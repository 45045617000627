import { atom, selector } from 'recoil';
import { persistAtom } from '../recoilConfig';
import userAtom from 'recoilState/authUser';
import { Supplier } from 'types';

const supplierToConnectState = atom({
  key: 'supplierToConnect',
  default: null as any,
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const selectedSupplier = atom({
  key: 'selectedSupplier',
  default: null as Supplier | null,
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const supplierSearchParams = atom({
  key: 'supplierSearchVal',
  default: '',
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const newSuppliers = atom({
  key: 'newSuppliers',
  default: [] as Supplier[],
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const searchSuppliersList = atom({
  key: 'searchSuppliersList',
  default: [] as Supplier[],
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const suppliersByDistrict = atom({
  key: 'suppliersByDistrict',
  default: [] as Supplier[],
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const viewByList = [
  {
    key: 'all_suppliers',
    desktop: 'All suppliers',
    mobile: 'All',
  },
  {
    key: 'my_suppliers',
    desktop: 'My suppliers',
    mobile: 'My suppliers',
  },
  {
    key: 'newly_onboarded',
    desktop: 'Newly onboarded',
    mobile: 'New',
  },
  {
    key: 'requested',
    desktop: 'Requests',
    mobile: 'Requests',
  },
];

const supplierViewBy = atom({
  key: 'supplierViewBy',
  default: viewByList[0].key,
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const connectionRequests = atom({
  key: 'connectionRequests',
  default: [] as any[],
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const connectedSuppliers = selector({
  key: 'connectedSuppliers',
  get: ({ get }) => {
    const requests = Array.isArray(get(connectionRequests)) ? get(connectionRequests).slice().reverse() : [];

    return requests.filter(req => req.connectStatus.status === 'Connected');
  },
});

const hideSuppliersSelector = selector({
  key: 'hideSuppliersSelector',
  get: ({ get }) => {
    const requests = Array.isArray(get(connectionRequests)) ? get(connectionRequests).slice().reverse() : [];
    const connectedSuppliers = requests.filter(req => req.connectStatus.status === 'Connected');
    let hide = true;
    const isConnectedWithMultiple = connectedSuppliers.length > 1;
    if (isConnectedWithMultiple) hide = false;
    else {
      hide = connectedSuppliers[0]?.supplierStore?.hideOtherSuppliersToRetailers;
    }
    return hide;
  },
});

const connectedSuppliersHash = selector({
  key: 'connectedSuppliersHash',
  get: ({ get }) => {
    const connectHash = {};
    if (Array.isArray(get(connectedSuppliers))) {
      get(connectedSuppliers).forEach(connect => {
        connectHash[connect?.supplierGuid] = connect;
      });
    }
    return connectHash;
  },
});

const connectedSuppliersIds = selector({
  key: 'connectedSuppliersIds',
  get: ({ get }) => {
    return get(connectedSuppliers).map(supplier => supplier?.supplierStore.storeId);
  },
});

const filteredConnectionRequests = selector({
  key: 'filteredConnectionRequests',
  get: ({ get }) => {
    const requestType = get(connectionRequestType);
    const requests = Array.isArray(get(connectionRequests)) ? get(connectionRequests).slice().reverse() : [];
    const viewBy = get(supplierViewBy);
    const user = get(userAtom);
    if (viewBy === viewByList[1].key) {
      return requests.filter(req => {
        return req.connectStatus.status === 'Connected';
      });
    }

    if (requestType === 'Sent Requests') {
      return requests.filter(req => {
        return (req.requestedBy === user.username || req.requestedBy === 'retailer') && req.connectStatus.status !== 'Connected';
      });
    }
    if (requestType === 'Recieved Requests')
      return requests.filter(req => {
        return req.requestedBy !== user.username && req.requestedBy !== 'retailer' && req.connectStatus.status !== 'Connected';
      });

    return requests.filter(req => req.connectStatus.status !== 'Connected');
  },
});

const connectionRequestType = atom({
  key: 'connectionRequestTypeSuppliers',
  default: 'All' as string,
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(newID => {});
    },
  ],
});

const suppliers = selector({
  key: 'suppliers',
  get: ({ get }) => {
    const viewBy = get(supplierViewBy);
    const searchVal = get(supplierSearchParams);
    const filterSuppliers = supplier =>
      supplier.supplierStore?.storeCode.toLowerCase().includes(searchVal.toLowerCase()) ||
      supplier.supplierStore?.name.toLowerCase().includes(searchVal.toLowerCase());

    if (viewBy === viewByList[0].key) {
      if (searchVal !== '') return get(searchSuppliersList);
      else return get(suppliersByDistrict);
    }
    if (viewBy === viewByList[1].key) {
      if (searchVal !== '') {
        return get(connectedSuppliers).filter(filterSuppliers);
      } else {
        return get(filteredConnectionRequests);
      }
    }
    if (viewBy === viewByList[2].key) {
      if (searchVal !== '') return get(newSuppliers).filter(filterSuppliers);
      return get(newSuppliers);
    }
    if (viewBy === viewByList[3].key) {
      // const requestType = get(connectionRequestType);
      if (searchVal !== '') {
        return get(filteredConnectionRequests).filter(filterSuppliers);
      } else {
        return get(filteredConnectionRequests);
      }
    }
    return [];
  },
});

// const connectedSuppliers = selector({
//   key: 'connectedSuppliers',
//   get: ({get}) => get(connectedSuppliersAtom),
//   set: async ({set}, newValue) =>
//   await fetch

//   set(
//       connectedSuppliersAtom,
//     newValue instanceof DefaultValue ? newValue : newValue
//   ),
// });

export {
  connectedSuppliers,
  selectedSupplier,
  supplierSearchParams,
  newSuppliers,
  searchSuppliersList,
  suppliersByDistrict,
  suppliers,
  supplierViewBy,
  viewByList,
  connectedSuppliersIds,
  connectionRequestType,
  connectionRequests,
  supplierToConnectState,
  connectedSuppliersHash,
  hideSuppliersSelector,
};
