import { atom } from 'recoil';
import { persistAtom } from '../recoilConfig';

import AuthUserType from './types';

const defaultUser = {
  role: 'guest',
};

const authUserAtom = atom({
  key: 'authUser',
  default: defaultUser as AuthUserType,
  effects: [
    persistAtom,
    ({ onSet }) => {
      onSet(user => {
        // if (user.username && user.role === 'retailer')
        // console.log('Current user ID:', user);
      });
    },
  ],
});

export default authUserAtom;
